import React from 'react';
import { Link } from 'react-router-dom';
import { Title } from '../../elements/title/title';
import { Paragraph } from '../../elements/paragraph/paragraph';

export const NzSupportsText = () => {
    return (
        <>
            <Title as="h2" text="nz supports" />
            <Paragraph>
                <Link to="/info/nz-supports">read here</Link> about the projects
                sauna nz supports
            </Paragraph>
        </>
    );
};
