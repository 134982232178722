import React from 'react';
import classNames from 'classnames';

import css from './title.module.scss';

interface TitleProps extends React.HTMLAttributes<HTMLTitleElement> {
    as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
    text: string;
    underlined?: boolean;
    isHome?: boolean;
    isBold?: boolean;
    className?: string;
    noMargin?: boolean;
    empty?: boolean;
}

export const Title: React.FC<TitleProps> = ({
    as: Tag,
    text,
    underlined = true,
    isHome = false,
    isBold = false,
    className,
    noMargin = false,
    empty = false,
}) => {
    const classes = classNames(
        className,
        css.root,
        underlined && css.underlined,
        !underlined && css.notUnderlined,
        isHome && css.isHome,
        isBold && css.isBold,
        noMargin && css.noMargin,
        empty && css.empty,
    );
    return <Tag className={classes}>{text}</Tag>;
};
