import { Image } from 'react-grid-gallery';

import thumb1 from './../../assets/sauna-visual-1b-small.jpg';
import pic1 from '../../assets/sauna-visual-1b.jpg';
import thumb2 from './../../assets/sauna-visual-2b-small.jpg';
import pic2 from './../../../common/assets/sauna-visual-2b.jpg';
import thumb3 from './../../assets/sauna-visual-3b-small.jpg';
import pic3 from './../../../common/assets/sauna-visual-3b.jpg';
import thumb4 from './../../assets/sauna-visual-4b-small.jpg';
import pic4 from './../../../common/assets/sauna-visual-4b.jpg';
import thumb5 from './../../assets/sauna-visual-5b-small.jpg';
import pic5 from './../../../common/assets/sauna-visual-5b.jpg';
import thumb6 from './../../assets/sauna-visual-6b-small.jpg';
import pic6 from './../../../common/assets/sauna-visual-6b.jpg';
import thumb7 from './../../assets/sauna-visual-7b-small.jpg';
import pic7 from './../../../common/assets/sauna-visual-7b.jpg';
import thumb8 from './../../assets/sauna-visual-8b-small.jpg';
import pic8 from './../../../common/assets/sauna-visual-8b.jpg';
import thumb9 from './../../assets/sauna-visual-9-small.jpg';
import pic9 from './../../../common/assets/sauna-visual-9.jpg';
import thumb10 from './../../assets/sauna-visual-10-small.jpg';
import pic10 from './../../../common/assets/sauna-visual-10.jpg';
import thumb11 from './../../assets/sauna-visual-11-small.jpg';
import pic11 from './../../../common/assets/sauna-visual-11.jpg';
import thumb12 from './../../assets/sauna-visual-12-small.jpg';
import pic12 from './../../../common/assets/sauna-visual-12.jpg';
import thumb13 from './../../assets/sauna-visual-13-small.jpg';
import pic13 from './../../../common/assets/sauna-visual-13.jpg';
import thumb14 from './../../assets/sauna-visual-14-small.jpg';
import pic14 from './../../../common/assets/sauna-visual-14.jpg';

export interface CustomImage extends Image {
    original: string;
}

export const images: CustomImage[] = [
    {
        src: thumb1,
        original: pic1,
        width: 132,
        height: 132,
    },
    {
        src: thumb2,
        original: pic2,
        width: 132,
        height: 132,
    },
    {
        src: thumb3,
        original: pic3,
        width: 132,
        height: 132,
    },
    {
        src: thumb4,
        original: pic4,
        width: 132,
        height: 132,
    },
    {
        src: thumb5,
        original: pic5,
        width: 132,
        height: 132,
    },
    {
        src: thumb6,
        original: pic6,
        width: 132,
        height: 132,
    },
    {
        src: thumb7,
        original: pic7,
        width: 132,
        height: 132,
    },
    {
        src: thumb8,
        original: pic8,
        width: 132,
        height: 132,
    },
    {
        src: thumb9,
        original: pic9,
        width: 132,
        height: 132,
    },
    {
        src: thumb10,
        original: pic10,
        width: 132,
        height: 132,
    },
    {
        src: thumb11,
        original: pic11,
        width: 132,
        height: 132,
    },
    {
        src: thumb12,
        original: pic12,
        width: 132,
        height: 132,
    },
    {
        src: thumb13,
        original: pic13,
        width: 132,
        height: 132,
    },
    {
        src: thumb14,
        original: pic14,
        width: 132,
        height: 132,
    },
];
