import React from 'react';
import { Title } from '../../elements/title/title';
import { Paragraph } from '../../elements/paragraph/paragraph';
import { GoogleMaps } from '../../features/google-maps/google-maps';

import css from './public-transport.module.scss';
import { ExternalLink } from '../../elements/external-link/external-link';

export const PublicTransport = () => {
    return (
        <>
            <Title as="h2" text="public transport" />
            <Paragraph>
                anything that stops at central station amsterdam.
            </Paragraph>
            <article className={css.map}>
                <GoogleMaps />
            </article>
            <Paragraph>
                <ExternalLink
                    href="https://maps.google.nl/maps?q=nieuwezijds+armsteeg+95&hl=nl&sll=52.377115,4.896126&sspn=0.002843,0.008256&hnear=Nieuwezijds+Armsteeg+95,+1012+NB+Centrum,+Amsterdam,+Noord-Holland&t=m&z=16"
                    text="Google Maps"
                />
            </Paragraph>
        </>
    );
};
