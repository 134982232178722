import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Home } from './pages/home/home';
import { Agenda } from './pages/agenda/agenda';
import { Info } from './pages/info/info';
import { Massage } from './pages/massage/massage';
import { Gallery } from './pages/gallery/gallery';
import { Links } from './pages/links/links';
import { Reservation } from './pages/reservation/reservation';
import { PrivacyStatement } from './pages/privacy-statement/privacy-statement';
import { MellowMonday } from './pages/agenda/mellow-monday/mellow-monday';
import { NoTowelTuesday } from './pages/agenda/no-towel-tuesday/no-towel-tuesday';
import { TheNightIsYoung } from './pages/agenda/the-night-is-young/the-night-is-young';
import { QueerNight } from './pages/agenda/queer-night/queer-night';
import { BearsAtNz } from './pages/agenda/bears-at-nz/bears-at-nz';
import { Richard } from './pages/massage/richard/richard';
import { Harald } from './pages/massage/harald/harald';
import { OurHouseRules } from './pages/info/our-house-rules/our-house-rules';
import { SexualConsentPolicy } from './pages/info/sexual-consent-policy/sexual-consent-policy';
import { OurTransgenderPolicy } from './pages/info/our-transgender-policy/our-transgender-policy';
import { NzSupports } from './pages/info/nz-supports/nz-supports';
import { Error } from './pages/error/error';
import { SexualHealth } from './pages/info/sexual-health/sexual-health';
import { EntrancePrices } from './pages/entrance-prices/entrance-prices';

const Routes = () => (
    <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/index.html" component={Home} exact />
        <Route path="/entrance-prices" component={EntrancePrices} exact />
        <Route path="/gallery" component={Gallery} exact />
        <Route path="/agenda" component={Agenda} exact />
        <Route path="/massage" component={Massage} exact />
        <Route path="/info/links" component={Links} exact />
        <Route path="/info" component={Info} exact />
        <Route path="/reservation" component={Reservation} exact />
        <Route
            path="/corona-safety-nl/privacy-statement"
            component={PrivacyStatement}
            exact
        />

        <Route path="/agenda/mellow-monday" component={MellowMonday} exact />
        <Route
            path="/agenda/no-towel-tuesday"
            component={NoTowelTuesday}
            exact
        />
        <Route path="/agenda/queer-night" component={QueerNight} exact />
        <Route
            path="/agenda/the-night-is-young"
            component={TheNightIsYoung}
            exact
        />
        <Route path="/agenda/bears-at-nz" component={BearsAtNz} exact />

        <Route path="/massage/richard" component={Richard} exact />
        <Route path="/massage/harald" component={Harald} exact />

        <Route path="/info/our-house-rules" component={OurHouseRules} exact />
        <Route
            path="/info/sexual-consent-policy"
            component={SexualConsentPolicy}
            exact
        />
        <Route path="/info/sexual-health" component={SexualHealth} exact />
        <Route
            path="/info/our-transgender-policy"
            component={OurTransgenderPolicy}
            exact
        />
        <Route path="/info/nz-supports" component={NzSupports} exact />

        <Route component={Error} />
    </Switch>
);

export default Routes;
