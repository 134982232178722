import React from 'react';
import classNames from 'classnames';

import css from './picture-block.module.scss';

import contentImg from './../../../common/assets/mood-image2.jpg';
import homeImg from './../../../common/assets/mood-image1.jpg';

interface PictureBlockProps extends React.HTMLAttributes<HTMLDivElement> {
    isHome?: boolean;
    imageSrc?: string;
    showPicture?: boolean;
}

export const PictureBlock: React.FC<PictureBlockProps> = ({
    isHome = false,
    imageSrc,
    showPicture = false,
}) => {
    const classes = classNames(
        css.root,
        isHome && css.isHome,
        showPicture && css.showPicture,
    );
    return imageSrc ? (
        <figure
            className={classes}
            style={{ backgroundImage: `url(${imageSrc})` }}
        />
    ) : (
        <figure
            className={classes}
            style={{
                backgroundImage: `url(${isHome ? homeImg : contentImg})`,
            }}
        />
    );
};
