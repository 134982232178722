import React from 'react';
import { Title } from '../../elements/title/title';
import { Paragraph } from '../../elements/paragraph/paragraph';
import { ExternalLink } from '../../elements/external-link/external-link';

export const Impression = () => {
    return (
        <>
            <Title as="h2" text="impression" />
            <Paragraph>
                <ExternalLink
                    href="https://youtu.be/zX8HDHVXt_A?si=mK11KKdJy5MMsIpX"
                    text="Click here"
                />{' '}
                to watch a video impression of Sauna NZ
            </Paragraph>
        </>
    );
};
