import React from 'react';
import { Link } from 'react-router-dom';
import { Title } from '../../elements/title/title';
import { Paragraph } from '../../elements/paragraph/paragraph';

export const OtherInformation = () => {
    return (
        <>
            <Title as="h2" text="other information" />
            <Paragraph>
                <Link to="/info/our-house-rules">our house rules</Link>
                <br />
                <Link to="/info/sexual-consent-policy">
                    sexual consent policy
                </Link>
                <br />
                <Link to="/info/sexual-health">sexual health</Link>
                <br />
                <Link to="/info/our-transgender-policy">
                    our transgender policy
                </Link>
                <br />
                <Link to="/info/links">gay businesses & groups</Link>
            </Paragraph>
        </>
    );
};
