import React from 'react';
import { Helmet } from 'react-helmet';
import { isMobileOnly } from 'react-device-detect';
import { isPortraitTablet } from '../../../app';
import { ScrollToTop } from '../../../common/scripts/scroll-to-top';
import { BackLink } from '../../../common/elements/back-link/back-link';
import { PictureBlock } from '../../../common/elements/picture-block/picture-block';
import { ContentBlock } from '../../../common/elements/content-block/content-block';
import { Title } from '../../../common/elements/title/title';
import { Paragraph } from '../../../common/elements/paragraph/paragraph';

import css from './../info.module.scss';
import { ExternalLink } from '../../../common/elements/external-link/external-link';

export const SexualConsentPolicy = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>
                    Sexual Consent Policy | Nieuwezijds Gay Sauna Amsterdam
                </title>
                <meta
                    name="description"
                    content="The sexual consent policy of the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <PictureBlock />
                <ContentBlock position="2">
                    {(isMobileOnly || isPortraitTablet) && (
                        <BackLink href="/info" text="info" />
                    )}
                    <Title as="h1" text="Sexual Consent Policy" />
                    <Paragraph>
                        to keep our sauna safe, tolerant and respectful, we
                        expect guests to follow some simple rules:
                    </Paragraph>
                    <ul className={css.list}>
                        <li className={css.listItem}>
                            respect each other’s boundaries. NO means NO. sex
                            must always be consensual, otherwise it is assault!
                        </li>
                        <li className={css.listItem}>
                            likewise accept others enthusiastically or decline
                            them respectfully.
                        </li>
                        <li className={css.listItem}>
                            respect other’s condom choice. everyone has the
                            right to protect themselves as they see fit.
                        </li>
                    </ul>
                    <Paragraph>
                        if you have problems with other guests please contact
                        staff
                    </Paragraph>
                    <Paragraph>
                        <ExternalLink
                            href="https://mantotman.nl/en/help-yourself/what-can-you-do-in-case-of-sexual-violence"
                            text="Click here for information about sex against your will"
                        />
                    </Paragraph>

                    <Title as="h2" text="seksueel toestemmingsbeleid" />
                    <Paragraph>
                        om onze sauna veilig, tolerant en respectvol te houden,
                        verwachten we dat gasten enkele eenvoudige regels
                        volgen:
                    </Paragraph>

                    <ul className={css.list}>
                        <li className={css.listItem}>
                            respecteer elkaars grenzen. NEE betekent NEE. seks
                            moet altijd vrijwillig zijn, anders is het
                            aanranding!
                        </li>
                        <li className={css.listItem}>
                            accepteer op dezelfde manier anderen met
                            enthousiasme of weiger ze respectvol.
                        </li>
                        <li className={css.listItem}>
                            respecteer de condoom keuze van anderen. iedereen
                            heeft het recht zichzelf te beschermen zoals zij dat
                            nodig achten.
                        </li>
                    </ul>

                    <Paragraph>
                        neem contact op met het personeel als u problemen hebt
                        met andere gasten.
                    </Paragraph>
                    <Paragraph>
                        <ExternalLink
                            href="https://mantotman.nl/nl/snel-regelen/hulp-na-seks-tegen-je-wil"
                            text="Klik hier voor informatie over seks tegen je wil"
                        />
                    </Paragraph>
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
