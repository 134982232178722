import React from 'react';
import { Helmet } from 'react-helmet';
import { ScrollToTop } from '../../common/scripts/scroll-to-top';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { Address } from '../../common/content/address/address';
import { OpeningHours } from '../../common/content/opening-hours/opening-hours';
import { OtherInformation } from '../../common/content/other-information/other-information';
import { NzSupportsText as NzSupportsContent } from '../../common/content/nz-supports-text/nz-supports-text';
import { PublicTransport } from '../../common/content/public-transport/public-transport';
import { Parking } from '../../common/content/parking/parking';
import { EntrancePricesTable } from '../../common/content/entrance-prices-table/entrance-prices-table';

import css from './info.module.scss';
import { Impression } from '../../common/content/impression/impression';

export const Info = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>Info | Nieuwezijds Gay Sauna Amsterdam</title>
                <meta
                    name="description"
                    content="General information about the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <ContentBlock position="1">
                    <Address />
                    <OpeningHours />
                    <EntrancePricesTable />
                    <OtherInformation />
                    <Impression />
                    <NzSupportsContent />
                </ContentBlock>
                <ContentBlock position="2">
                    <PublicTransport />
                    <Parking />
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
