import React from 'react';
import { Helmet } from 'react-helmet';
import { PictureBlock } from '../../../common/elements/picture-block/picture-block';
import { ContentBlock } from '../../../common/elements/content-block/content-block';
import { Title } from '../../../common/elements/title/title';
import { ScrollToTop } from '../../../common/scripts/scroll-to-top';
import { isMobileOnly } from 'react-device-detect';
import { isPortraitTablet } from '../../../app';
import { BackLink } from '../../../common/elements/back-link/back-link';
import { Paragraph } from '../../../common/elements/paragraph/paragraph';
import { ExternalLink } from '../../../common/elements/external-link/external-link';

import css from './../info.module.scss';

import transMan from '../../../common/assets/what_is_a_transman.pdf';

export const OurTransgenderPolicy = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>
                    Our Transgender Policy | Nieuwezijds Gay Sauna Amsterdam
                </title>
                <meta
                    name="description"
                    content="The transgender policy of the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <PictureBlock />
                <ContentBlock position="2">
                    {(isMobileOnly || isPortraitTablet) && (
                        <BackLink href="/info" text="info" />
                    )}
                    <Title as="h1" text="Our transgender policy" />
                    <Paragraph>
                        nz is open to exclusively men including trans men,
                        except on 'Queer Night' when all genders are welcome.
                    </Paragraph>
                    <Paragraph>
                        want to know more about trans men?{' '}
                        <ExternalLink href={transMan} text="click here" />
                    </Paragraph>
                    <Paragraph>
                        all information supplied from{' '}
                        <ExternalLink href="http://tm4m.org" text="tm4m.org" />{' '}
                        and{' '}
                        <ExternalLink
                            href="http://dudemagazine.wordpress.com"
                            text="dudemagazine.wordpress.com"
                        />
                    </Paragraph>
                    <Paragraph>
                        please note - for those uncomfortable with full nudity,
                        be aware that one of our shower blocks has a glass wall
                        and so is visible from the dry sauna.
                    </Paragraph>
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
