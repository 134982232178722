import React from 'react';
import { Helmet } from 'react-helmet';
import { isMobileOnly } from 'react-device-detect';
import { isPortraitTablet } from '../../../app';
import { BackLink } from '../../../common/elements/back-link/back-link';
import { PictureBlock } from '../../../common/elements/picture-block/picture-block';
import { ContentBlock } from '../../../common/elements/content-block/content-block';
import { Title } from '../../../common/elements/title/title';
import { ScrollToTop } from '../../../common/scripts/scroll-to-top';
import { Paragraph } from '../../../common/elements/paragraph/paragraph';
import { ExternalLink } from '../../../common/elements/external-link/external-link';

import css from '../massage.module.scss';

import richardImg from '../../../common/assets/masseur-richard-big.jpg';

export const Richard = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>
                    Masseur Richard | Massage | Nieuwezijds Gay sauna Amsterdam
                </title>
                <meta
                    name="description"
                    content="Information about Robert, one of the masseurs available in the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <PictureBlock showPicture imageSrc={richardImg} />
                <ContentBlock position="2">
                    {(isMobileOnly || isPortraitTablet) && (
                        <BackLink href="/massage" text="massage" />
                    )}
                    <Title as="h1" text="Richard Hildalgo" />
                    <Paragraph>
                        richard is a certified massage therapist with over 10
                        years of experience, specialized in deep tissue massage,
                        reflexology massage and chiromassage which he studied at
                        the itm school in chiang mai and at the escuela de
                        sanidad y salud in madrid.
                    </Paragraph>

                    <Paragraph>
                        richard offers you a wide range of massages from a
                        relaxing swedish massage to more specialised treatments
                        to help you recover from muscle injury and pain.
                    </Paragraph>

                    <Paragraph>
                        in these challenging times it is important to look after
                        our body & mind to keep healthy, both physically &
                        mentally.
                    </Paragraph>

                    <Paragraph>
                        as a masseur he comes into contact with people from all
                        walks of life. this gives him the skills to build and
                        maintain a good relationship with all of his clients.
                    </Paragraph>

                    <Paragraph>
                        if you need help making your choice, please don't
                        hesitate to contact him so you can discuss which
                        treatment is best for you.
                    </Paragraph>

                    <Paragraph>
                        For more information or to make an appointment:
                        <br />
                        <ExternalLink
                            href="http://www.facebook.com/richsportmassage"
                            text="www.facebook.com/richsportmassage"
                        />
                        <br />
                        <ExternalLink
                            href="http://www.richardmassage.com"
                            text="www.richardmassage.com"
                        />
                        <br />
                        or give me a call on{' '}
                        <ExternalLink
                            href="tel:31611766187"
                            text="+31611766187"
                        />
                    </Paragraph>
                    <Paragraph>
                        book a session at the bar or call in advance to make a
                        reservation:
                        <br />
                        <ExternalLink
                            className={css.big}
                            href="tel:31203318327"
                            text="+31 20 331 8327"
                        />
                    </Paragraph>
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
