import React from 'react';
import { Title } from '../../elements/title/title';
import { Paragraph } from '../../elements/paragraph/paragraph';
import { ExternalLink } from '../../elements/external-link/external-link';

export const Address = () => {
    return (
        <>
            <Title as="h2" text="address" />
            <Paragraph>
                nieuwezijds armsteeg 95
                <br />
                1012 nb amsterdam
                <br />
                tel: +31 20 331 8327
                <br />
                <ExternalLink
                    href="mailto:manager@saunanieuwezijds.nl"
                    text="manager@saunanieuwezijds.nl"
                />
            </Paragraph>
        </>
    );
};
