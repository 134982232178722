import React, { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router';

export const ScrollToTop: React.FC<PropsWithChildren> = ({ children }) => {
    const { hash } = useLocation();

    useEffect(() => {
        setTimeout(() => {
            !hash && window.scrollTo({ top: 0 });
        }, 1);
    }, [hash]);

    return <>{children}</>;
};
