import React from 'react';
import { Helmet } from 'react-helmet';
import { isMobileOnly } from 'react-device-detect';
import { isPortraitTablet } from '../../../app';
import { BackLink } from '../../../common/elements/back-link/back-link';
import { PictureBlock } from '../../../common/elements/picture-block/picture-block';
import { ContentBlock } from '../../../common/elements/content-block/content-block';
import { Title } from '../../../common/elements/title/title';
import { ScrollToTop } from '../../../common/scripts/scroll-to-top';
import { Paragraph } from '../../../common/elements/paragraph/paragraph';

import css from '../agenda-item.module.scss';

import genderFluids from '../../../common/assets/gender-fluids.jpg';

export const QueerNight = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>
                    Queer Night | Agenda | Nieuwezijds Gay Sauna Amsterdam
                </title>
                <meta
                    name="description"
                    content="Information about Queer Nights, one of the events in the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <PictureBlock imageSrc={genderFluids} />
                <ContentBlock position="2">
                    {(isMobileOnly || isPortraitTablet) && (
                        <BackLink href="/agenda" text="agenda" />
                    )}
                    <Title as="h1" text="Queer Night" noMargin />
                    <Title
                        as="h2"
                        text="all queer people welcome!"
                        underlined={false}
                    />
                    <Paragraph>all day, every wednesday</Paragraph>

                    <ul className={css.list}>
                        <li>
                            aufgüss/infusion ceremony (dry sauna) at 7pm & 9 pm
                        </li>
                        <li>chocolate session (steam sauna) at 8pm</li>
                    </ul>

                    <Paragraph>
                        <strong>Note:</strong> NZ is a sauna exclusively for gay
                        men. every wednesday however we offer a safe space for
                        all queer people
                    </Paragraph>
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
