import React from 'react';
import { Helmet } from 'react-helmet';
import { ScrollToTop } from '../../common/scripts/scroll-to-top';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { PictureBlock } from '../../common/elements/picture-block/picture-block';
import { EntrancePricesTable } from '../../common/content/entrance-prices-table/entrance-prices-table';

import css from './entrance-price.module.scss';

export const EntrancePrices = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>Links | Nieuwezijds Gay Sauna Amsterdam</title>
                <meta
                    name="description"
                    content="Website links to the friends of the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <PictureBlock />
                <ContentBlock position="2">
                    <section className={css.container}>
                        <div className={css.listBlock}>
                            <EntrancePricesTable />
                        </div>
                    </section>
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
